import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { rhythm } from "../../utils/typography"

const BioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const useSitedata = () => {
  const data = useStaticQuery(
    graphql`
      query {
        avatar: file(absolutePath: { regex: "/yolokuder.png/" }) {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            author
            social {
              twitter
            }
          }
        }
      }
    `
  )
  return data
}

export default ({ children }) => {
  const { avatar, site } = useSitedata()
  const { social, author } = site.siteMetadata

  return (
    <div>
      <BioWrapper>
        <Image
          fixed={avatar.childImageSharp.fixed}
          alt={author}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <div>
          Written by <strong>{author}</strong> who lives and works in Da Nang
          city building useful things.{" "}
          <a href={`https://twitter.com/${social.twitter}`}>
            {" "}
            You should follow him on Twitter
          </a>
        </div>
      </BioWrapper>
      {children}
    </div>
  )
}
