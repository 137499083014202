import React from "react"
import styled from "styled-components"
import Theme from "../Theme"

import Bio from "../Bio"

const { Colors } = Theme

const FooterWrapper = styled.section`
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0;
  min-height: 5rem;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  color: ${Colors.black};
  opacity: 0.9;
`

const Footer = ({ children }) => {
  return (
    <FooterWrapper>
      <Bio />
      {children}
    </FooterWrapper>
  )
}

export default Footer
