// import React from "react"
import styled from "styled-components"

import Devices from "../BreakPoint/Devices"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem;
  max-width: 1800px;

  /* @media ${Devices.tablet} {
    margin-left: 45rem;
  } */
`

export default Container
