import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Theme from "../Theme"

const { Colors } = Theme

const LogoStyle = styled(Link)`
  display: flex;
  height: 80%;
  align-items: center;
  color: ${Colors.darker};
  text-transform: uppercase;
  border-radius: 40px;
  transition: background 0.2s ease;
  width: 20rem;
  margin-right: 2.5rem;

  background-image: linear-gradient(
    to right,
    ${Colors.subtle} 0%,
    ${Colors.lighter} 100%
  );

  & span {
    align-items: center;
    background: ${Colors.white};
    border-radius: 40px;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    width: 100%;
    font-weight: 700;
  }

  &:hover span {
    color: ${Colors.white};
    background: transparent;
  }
`

const Logo = () => {
  let text = `< Yolo ♥ Code />`
  return (
    <LogoStyle to="/">
      <span>{text}</span>
    </LogoStyle>
  )
}
export default Logo
