// import styled from 'styled-components'

const BreakpointSize = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const Devices = {
  mobileS: `(min-width: ${BreakpointSize.mobileS})`,
  mobileM: `(min-width: ${BreakpointSize.mobileM})`,
  mobileL: `(min-width: ${BreakpointSize.mobileL})`,
  tablet: `(min-width: ${BreakpointSize.tablet})`,
  laptop: `(min-width: ${BreakpointSize.laptop})`,
  laptopL: `(min-width: ${BreakpointSize.laptopL})`,
  desktop: `(min-width: ${BreakpointSize.desktop})`,
  desktopL: `(min-width: ${BreakpointSize.desktop})`,
}

export default Devices
