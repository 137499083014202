import React from "react"
import styled from "styled-components"

import Header from "../Header"
import Footer from "../Footer"

import Theme from "../Theme"
import PrismTheme from "../PrismTheme/PrismTheme"

const { Colors } = Theme

const MasterHeader = styled(Header)`
  color: ${Colors.green};
  background-color: ${Colors.red};
  height: 10rem;
`

const MasterFooter = styled(Footer)`
  height: 8rem;
`

const MasterLayoutStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 6rem;
  margin-top: 6rem;
  margin-bottom: 8rem;
`

const MasterLayout = ({ children }) => {
  return (
    <>
      <MasterHeader></MasterHeader>
      <MasterLayoutStyle>{children}</MasterLayoutStyle>
      <MasterFooter></MasterFooter>
      <PrismTheme></PrismTheme>
    </>
  )
}

export default MasterLayout
