import React from "react"
import styled from "styled-components"
import Theme from "../Theme"
import Logo from "../Logo"
import HeaderNavigation from "./HeaderNavigation"
import SearchInput from "../SearchInput"

const { Colors } = Theme

const HeaderStyle = styled.header`
  background-color: ${Colors.grey.grey_90};
  color: ${Colors.white};
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  font-size: 1.8rem;
`

const Header = ({ children }) => {
  return (
    <HeaderStyle>
      <Logo></Logo>
      <HeaderNavigation></HeaderNavigation>
      <SearchInput>Search box here...</SearchInput>
      {children}
    </HeaderStyle>
  )
}

export default Header
