import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Theme from "../Theme"

const { Colors } = Theme

const HeaderLinkStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s ease-out;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 300;
  height: 100%;

  &:hover:not(:focus) a {
    color: ${Colors.brand};
  }
`

const LinkStyle = styled(Link)`
  color: ${props => (props.isActive ? Colors.brand : Colors.white)};
`

const ActiveAfterStyle = styled.span`
  position: absolute;
  bottom: -1px;
  height: 4px;
  background: ${Colors.brand};
  left: 0px;
  right: 0px;
  z-index: 1;
  display: inline-block;
`

const HeaderLink = ({ to, title, isActive }) => {
  return (
    <HeaderLinkStyle>
      <LinkStyle to={to} isActive={isActive}>
        {title}
      </LinkStyle>
      {isActive && <ActiveAfterStyle></ActiveAfterStyle>}
    </HeaderLinkStyle>
  )
}

export default HeaderLink
