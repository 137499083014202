import React from "react"
import styled from "styled-components"
import Theme from "../Theme"

const { Colors } = Theme

const SearchInputStyle = styled.input.attrs(props => ({}))`
  background-color: transparent;
  border: 1px solid ${Colors.white};
  color: ${Colors.white};
  border-radius: 30px;
  padding-left: 1rem;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  ::placeholder {
    color: ${Colors.white};
  }
`

const SearchInput = props => {
  return <SearchInputStyle small placeholder="Search"></SearchInputStyle>
}

export default SearchInput
