const Colors = {
  lighter: "#373940",
  dark: "#282c34",
  darker: "#20232a",
  brand: "#61dafb",
  brandLight: "#bbeffd",
  text: "#1a1a1a",
  subtle: "#6d6d6d",
  subtleOnDark: "#999",
  divider: "#ececec",
  note: "#ffe564",
  error: "#ff6464",
  purple: {
    purple_90: `#362066`,
    purple_80: `#452475`,
    purple_70: `#542c85`,
    purple_60: `#663399`,
    purple_50: `#8a4baf`,
    purple_40: `#b17acc`,
    purple_30: `#d9bae8`,
    purple_20: `#f1defa`,
    purple_10: `#f6edfa`,
    purple_05: `#fcfaff`,
  },
  orange: {
    orange_90: `#db3a00`,
    orange_80: `#e65800`,
    orange_70: `#f67300`,
    orange_60: `#fb8400`,
    orange_50: `#ffb238`,
    orange_40: `#ffd280`,
    orange_30: `#ffe4a1`,
    orange_20: `#ffedbf`,
    orange_10: `#fff4db`,
    orange_05: `#fffcf7`,
  },
  yellow: {
    yellow_90: `#8a6534`,
    yellow_80: `#bf9141`,
    yellow_70: `#e3a617`,
    yellow_60: `#fec21e`,
    yellow_50: `#fed038`,
    yellow_40: `#ffdf37`,
    yellow_30: `#ffeb99`,
    yellow_20: `#fff2a8`,
    yellow_10: `#fff5bf`,
    yellow_05: `#fffdf7`,
  },
  red: {
    red_90: `#b80000`,
    red_80: `#ce0009`,
    red_70: `#da0013`,
    red_60: `#ec1818`,
    red_50: `#fa2915`,
    red_40: `#ff5a54`,
    red_30: `#ff8885`,
    red_20: `#ffbab8`,
    red_10: `#fde7e7`,
    red_05: `#fffafa`,
  },
  magenta: {
    magenta_90: `#690147`,
    magenta_80: `#7d0e59`,
    magenta_70: `#940159`,
    magenta_60: `#a6026a`,
    magenta_50: `#bc027f`,
    magenta_40: `#d459ab`,
    magenta_30: `#e899ce`,
    magenta_20: `#f2c4e3`,
    magenta_10: `#ffe6f6`,
    magenta_05: `#fffafd`,
  },
  blue: {
    blue_90: `#004ca3`,
    blue_80: `#006ac1`,
    blue_70: `#047bd3`,
    blue_60: `#0e8de6`,
    blue_50: `#0d96f2`,
    blue_40: `#3fa9f5`,
    blue_30: `#63b8f6`,
    blue_20: `#90cdf9`,
    blue_10: `#dbf0ff`,
    blue_05: `#f5fcff`,
  },
  teal: {
    teal_90: `#008577`,
    teal_80: `#10a39e`,
    teal_70: `#00bdb6`,
    teal_60: `#2de3da`,
    teal_50: `#05f7f4`,
    teal_40: `#73fff7`,
    teal_30: `#a6fffa`,
    teal_20: `#ccfffc`,
    teal_10: `#dcfffd`,
    teal_05: `#f7ffff`,
  },
  green: {
    green_90: `#006500`,
    green_80: `#088413`,
    green_70: `#1d9520`,
    green_60: `#2ca72c`,
    green_50: `#37b635`,
    green_40: `#59c156`,
    green_30: `#79cd75`,
    green_20: `#a1da9e`,
    green_10: `#def5dc`,
    green_05: `#f7fdf7`,
  },
  grey: {
    grey_90: `#232129`,
    grey_80: `#36313d`,
    grey_70: `#48434f`,
    grey_60: `#635e69`,
    grey_50: `#78757a`,
    grey_40: `#b7b5bd`,
    grey_30: `#d9d7e0`,
    grey_20: `#f0f0f2`,
    grey_10: `#f5f5f5`,
    grey_05: `#fbfbfb`,
  },
  white: `#ffffff`,
  black: `#000000`,
}

export default { Colors }
