import React, { Component } from "react"
import styled from "styled-components"
import HeaderLink from "./HeaderLink"
import Devices from "../BreakPoint/Devices"

import navHeader from "../../../contents/headerNav.yml"

const HeaderNavigationStyle = styled.nav`
  display: none;
  @media ${Devices.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
  }
`

class HeaderNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname: ``,
    }
  }

  componentDidMount() {
    this.setState({
      pathname: window.location.pathname,
    })
  }

  render() {
    return (
      <HeaderNavigationStyle>
        {navHeader.items.map(link => (
          <HeaderLink
            key={link.title}
            isActive={this.state.pathname.includes(link.activeSelector)}
            title={link.title}
            to={link.to}
          />
        ))}
      </HeaderNavigationStyle>
    )
  }
}

export default HeaderNavigation
